import styled, { css } from 'styled-components';
import { Dropdown, DropdownButton } from "react-bootstrap";

import { StyledTabContainer } from '../UserProfile/styles';
import { StyledDateTime } from '../../components/CreateEvent/styles';

const BoldFont = 'Rubik-Bold';
const RegularFont = 'Rubik-Regular';

const StyledTab = styled(StyledTabContainer)`
  .fade {
    transition: none;
  }
  padding: 20px 20px 1px 33px !important;
  font-size: 12px;
  li{
    text-transform: uppercase !important;
  }
  &:hover{
    color: #0D4270 !important;
  }
  button{
    background: none !important;
    border:none !important;
    font-size: 12px !important;
    letter-spacing: 0px !important;
    color: rgb(153, 153, 153) !important;
    font-family: Rubik-Bold !important;
    margin-right: 85px !important;
    text-transform: uppercase !important;
    padding: 5px 5px 17px !important;
  }
  button:hover{
    color: #0D4270;
  }
  .active{
    font-family: Rubik-Bold;
    background-color: transparent;
    color: rgb(13, 66, 112) !important;
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    border-bottom: 3px solid rgb(13, 66, 112) !important;
    cursor: pointer;
    font-family: Rubik-Medium;
  }
  @media (max-width: 500px) {
    > ul {
      > li {
        width: calc(100%/3) !important;
        > a {
          margin: 0 5px !important;
}
        }
        >button{
          border:none !important;
        }
      }
    }
  }
`;

const EditProfilePadding = styled.div`
  float: left;
  padding: 30px 38px 8px 40px;
  border-bottom: ${({ bottomBorder }) => bottomBorder ? '1px solid lightgray': ''};
  font-size: 14px;
  width: 100%;
  display: ${({displayNone}) => displayNone && 'none'};
  font-family:${RegularFont};
  .btn-group {
    display: block;
    float: left;
    width: 100%;
  }

  .dropdown-menu {
    width: 100%;
    max-height: 250px;
    overflow-x: hidden;
    min-width: 100% !important;
    >li>a {
      white-space: normal !important;
    }
  }

  .dropdown-toggle.btn-default:hover, .dropdown open btn-group  {
    background-color: white;
    border-color: #ccc;
    box-shadow: none;
  }

  @media (max-width: 500px) {
    padding: 30px 15px 8px;
  }
  .edit-profile-pic {
    margin-top: 13px;
}
  }
   .addImg {
  background-color: ${(props) => props.color || 'rgb(39 178 239 / 61%)'};
  border: ${(props) => props.color ? `1px solid ${props.color}` : '1px solid rgb(39 160 239)'};
  color: white;
  white-space: nowrap;
  padding: ${({ addMargin }) => addMargin ? '0' : '7px 10px'};
  width: ${({ addMargin }) => addMargin ? '158px' : 'auto'};
  height: ${({ addMargin }) => addMargin ? '35px' : 'auto'};
  font-size: 12px;
  font-style: normal;
  line-height: 1.428571429;
  border-radius: 30px;
  text-transform: capitalize;
  font-family: ${BoldFont};
  margin-top: 10px;
  margin-bottom: ${(props) => props.addMargin ? '55px' : '0'};
  // float: ${(props) => props.float ? 'right' : 'left'};
  &:focus, &:active, &:hover {
    outline: none;
  }
  
  > i {
    margin-right: 5px;
  }

  ${({ disabled }) => disabled === true && css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `}
  
  @media only screen and (max-width: 767px) {
    margin-bottom: 15px;
  }
  
  ${({ addMargin }) => addMargin && css`
    @media (max-width: 500px) {
      margin-bottom: 5px;
    }
  `}
   }
`;

const ActivityDropdown = styled(DropdownButton)`
  width: 100%;
  text-align: left;
  margin-top: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${({ showfont }) => showfont ? '16px ': '14px'};
  font-weight: ${({ showfont }) => showfont ? 'normal': 'bold'};
  button{
    width: 100%;
    color: black;
    border-color: rgb(204, 204, 204);
    background: none;
  }
  button:hover, button:focus, button:active{
    color: black !important;
    border-color: rgb(204, 204, 204) !important;
    background: none !important;
  }
  button::after{
    margin-left: 0;
    float: right;
    margin-top: 7px;
    border-top: 7px dashed black;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
  background-color: white !important;
  .settingOption {
    width: 85%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
  }

  > span {
    top: 10px;
    right: 10px;
    position: absolute;
    float: right;
    display: block;
    margin-top: 7px;
    border-bottom: 5px solid transparent;
    border-left: 7px solid black;
    border-top: 5px solid transparent;
  }
  ${({ opened }) => opened && css`
      > span {
        float: right;
        margin-top: 7px;
        border-top: 7px dashed black;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
      }
    `}

  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
    background-color: white;
    border-color: #ccc;
    box-shadow: none;
    font-size: ${({ showfont }) => showfont ? '16px ': '14px'};
    font-weight: ${({ showfont }) => showfont ? ' ': 'bold'};
  }
`;

const SingleTile = styled.div`
  float: left;
  display: inline-block;
  width: ${({ width }) => width+'%'};
  margin-left: ${({ marginLeft }) => marginLeft+'%'};
  margin-right: ${({ marginRight }) => marginRight ? marginRight+'%' : '0'};
  margin-bottom: 30px;
  @media only screen and (max-width: 500px) {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  > select {
    border-color: #ccc;
    padding: 12px 10px;
    width: 100%;
    text-align: left;
    margin-top: 15px;
    font-size: 14px;
    font-weight: bold;
    height: 46px;
    background-color: white !important;
  }
  .ceIlNc .rdtPicker {
    width: 100%;
  }
  ${({ hideDropDown }) => hideDropDown && css`
    display: none;
  `}
`;

const SettingsName = styled.span`
  font-size: ${({EditPage}) => EditPage ? '16px' : '14px'};
  text-transform: ${({EditPage}) => EditPage && 'uppercase'};
  font-family: ${BoldFont};
  padding-bottom: ${({ padding }) => padding ? '15px ': '0px'};
  float: left;
  .important {
    color: red;
    margin-left: 2px;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

const StyledInput = styled.input`
  padding: 0 12px;
  width: 100%;
  text-align: left;
  margin-top: ${({ marginTop }) => marginTop ? '-15px': '15px'};
  border: 1px solid #ccc;
  height: 46px;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: ${({ marginTop }) => marginTop ? '30px' : '0'};

  &:focus, &:hover, &:active {
    outline: none;
  }
`;

const CustomDateTime = styled(StyledDateTime)`
  color: black;
  font-size: 14px;
`;

const GenderDropdownNew = styled(Dropdown)`
  margin-top: 30px;
  button {
    min-height: 46px;
    width: 100%;
    color: black;
    border-color: rgb(204, 204, 204);
    background: none;
    text-align: left;
  }
  button:hover,
  button:focus,
  button:active, 
  button:focus-visible {
    color: black !important;
    border-color: rgb(204, 204, 204) !important;
    background: none !important;
  }
  button::after {
    margin-left: 0;
    float: right;
    margin-top: 7px;
    border-top: 7px dashed black;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
  .dropdown-item:focus,{
    background-color: none;
  }

  .btn-group.open .dropdown-toggle{
    -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
  }
`;

const CountryDropdown = styled(Dropdown)`
  margin-top: 30px;
  button {
    min-height: 46px;
    width: 100%;
    color: black;
    border-color: rgb(204, 204, 204);
    background: none;
    text-align: left;
  }
  button:hover,
  button:focus,
  button:active,
  button:focus-visible {
    color: black !important;
    border-color: rgb(204, 204, 204) !important;
    background: none !important;
  }
  button::after {
    margin-left: 0;
    float: right;
    margin-top: 7px;
    border-top: 7px dashed black;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
  .dropdown-item:focus {
    background-color: none;
  }

  .btn-group.open .dropdown-toggle {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
`;

const StateDropdownNew = styled(Dropdown)`
  margin: ${({ margin }) => margin ? margin : '0'};
  button {
    min-height: 46px;
    width: 100%;
    color: black;
    border-color: rgb(204, 204, 204);
    background: none;
    text-align: left;
  }
  button:hover,
  button:focus,
  button:active,
  button:focus-visible {
    color: black !important;
    border-color: rgb(204, 204, 204) !important;
    background: none !important;
  }
  button::after {
    margin-left: 0;
    float: right;
    margin-top: 7px;
    border-top: 7px dashed black;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
  .dropdown-item:focus {
    background-color: none;
  }

  .btn-group.open .dropdown-toggle {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
`;

export { StyledTab, EditProfilePadding, ActivityDropdown, SingleTile, SettingsName, StyledInput, CustomDateTime, GenderDropdownNew, CountryDropdown, StateDropdownNew };