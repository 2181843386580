import React, {Component} from 'react';
import {Tab} from "react-bootstrap";
import {StyledTabs} from "../../../components/EmployeesOfTheMonth/styles";
import PropTypes from "prop-types";
import ChangePassword from "../../../components/ChangePassword";
import ChangeEmail from "../../../components/ChangeEmail";
import {withTranslation} from 'react-i18next';

class UpdatedCredentials extends Component{
  render() {
    const {resetPassword,t} = this.props;
    return (
      <div>
        <StyledTabs defaultActiveKey={1} id="styledTabs">
          <Tab
            eventKey={1}
            title={t("CHANGE EMAIL")}
            tabClassName="first-tab"
          >
            <ChangeEmail />
          </Tab>
          <Tab
            eventKey={2}
            title={t("CHANGE PASSWORD")}
            tabClassName="second-tab"
          >
            <ChangePassword resetPassword={resetPassword}/>
          </Tab>
        </StyledTabs>
      </div>
    );
  }
}

UpdatedCredentials.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  t: PropTypes.func     
}
export default (withTranslation()(UpdatedCredentials));